<template>

    <div class="human-body-landing">

        <img src="@/assets/images/human-body/img-landing-bg-mobile.webp" class="landing-bg-mobile">
        <img src="@/assets/images/human-body/img-landing-bg.webp" class="landing-bg">

        <h1 class="desktop">Effects of<br>altitude on the<br>human body</h1>
        <h1 class="mobile">Effects of<br>altitude<br>on the<br>human body</h1>
        <h2>What does it feel like<br><span>to climb Mount Everest?</span></h2>

        <button @click="gotoHumanBodyExperience"></button>

    </div>

</template>


<script>

export default {

    name: 'HumanBodyLanding',

    components: {
        
    },

    data() {
        return {
            componentName: this.$options.name + ' View'
        };
    },

    computed: {

    },
    
    created() {
        
    },
    
    mounted() {
        
    },
    
    methods: {
        /**
         * @function gotoHumanBodyExperience
         * @description
         * 
         */
        gotoHumanBodyExperience() {
            this.$router.push({ name: 'HumanBodyExperience' })
        }
    }
    
}

</script>


<style lang="scss" scoped>

.human-body-landing {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    .landing-bg-mobile {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: none;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:portrait) {
            display: block;
        }
    }

    & .landing-bg {
        position: absolute;
        width: 200%;
        left: -60%;
        top: 0%;
        display: block;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:portrait) {
            display: none;
        }

        @media (min-width: 576px) { // RED (SM)
            width: 175%;
            left: -40%;
            top: -1%;
        }
        @media (min-width: 768px) { // GREEN (MD)
            width: 150%;
            left: -25%;
            top: -2%;
        }
        @media (min-width: 992px) { // BLUE (LG)
            width: 125%;
            left: -15%;
            top: -3%;
        }
        @media (min-width: 1200px) { // YELLOW (XL)
            width: 110%;
            left: -7%;
            top: -4%;
        }
        @media (min-width: 1400px) { // PURPLE (XXL)
            width: 100%;
            left: 0%;
            top: -5%;
        }
    }

    & h1.mobile,
    & h1.desktop {
        position: relative;
        background: linear-gradient(to bottom, #313b78, #62a9cc);
        color: #22226b;
        font-weight: $font-weight-light;
        margin: 0;
        user-select: none;
        display: inline-block;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        text-transform: uppercase;
        font-size: 37px;
        line-height: 48px;
        letter-spacing: 4px;

        @media (min-width: 576px) { // RED (SM)
            font-size: 77px;
            line-height: 90px;
            letter-spacing: 7px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }
    }

    & h1.desktop {
        display: block;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:portrait) {
            display: none;
        }
    }

    & h1.mobile {
        display: none;

        @media screen and (min-width:320px) and (max-width:767px) and (orientation:portrait) {
            display: block;
        }
    }

    & h2 {
        position: relative;
        color: #313b78;
        font-weight: $font-weight-bold;
        margin: 45px 0;
        user-select: none;
        text-transform: uppercase;
        font-size: 17px;
        line-height: 22px;

        @media (min-width: 576px) { // RED (SM)
            font-size: 24px;
            line-height: 30px;
        }
        @media (min-width: 768px) { // GREEN (MD)

        }
        @media (min-width: 992px) { // BLUE (LG)

        }
        @media (min-width: 1200px) { // YELLOW (XL)

        }
        @media (min-width: 1400px) { // PURPLE (XXL)

        }

        & span {
            color: #3f75ad;
            font-weight: $font-weight-regular;
            font-size: 15px;

            @media (min-width: 576px) { // RED (SM)
                font-size: 21px;
            }
            @media (min-width: 768px) { // GREEN (MD)

            }
            @media (min-width: 992px) { // BLUE (LG)

            }
            @media (min-width: 1200px) { // YELLOW (XL)

            }
            @media (min-width: 1400px) { // PURPLE (XXL)

            }
        }
    }

    & button {
        position: relative;
        background-image: url('../../assets/images/human-body/img-landing-btn.webp');
        background-size: 230px 100px;
        border: none;
        width: 230px;
        height: 50px;
        cursor: pointer;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, .2);

        &:hover {
            background-position-y: -50px;
        }
    }
    
}

</style>